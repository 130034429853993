import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import './Business.css';
import { CardMedia } from '@material-ui/core';

class Business extends React.Component {
    render() {
        return(
            <Card className="Business">
                <CardHeader 
                    avatar = {
                        <Avatar aria-label="restaurant">
                            {this.props.business.name.slice(0,1)}
                        </Avatar>
                    }
                    title={
                        <Typography component="h2">{this.props.business.name}</Typography>
                    }
                    subheader={this.props.business.rating}
                />
                <CardActionArea>
                    <CardMedia 
                        className="image-container"
                        image={this.props.business.imageSrc}
                        title={this.props.business.name}
                    />
                    <CardContent className="Business-information">
                        <div className="Business-address">
                            <Typography component="p">{this.props.business.address}</Typography>
                            <Typography component="p">{this.props.business.city}</Typography>
                            <Typography component="p">{this.props.business.state} {this.props.business.zipCode}</Typography>
                            <Button size="small" color="primary" href={"https://maps.google.com/?q="+this.props.business.address+","+this.props.business.city+","+this.props.business.state} target="blank">Go there now!</Button>
                        </div>
                        <div className="Business-reviews">
                        <Typography component="h3">{this.props.business.category}</Typography>
                        <Typography component="h3" className="rating">{this.props.business.rating}</Typography>
                        <Typography component="p">{this.props.business.reviewCount} reviews</Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
}

export default Business;