import React from 'react';

import Ravenous from '../Ravenous/Ravenous'
import WeatherIndex from '../Weather/WeatherIndex'

import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    )
}

export default function App() {
    const [value, setValue] = React.useState(0)

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
      <div className="App">
        <AppBar>
            <Tabs
                value={value}
                onChange={handleTabChange}
                centered
            >
                <Tab label="Ravenous" />
                <Tab label="WeatherIndex" />
            </Tabs>
        </AppBar>
        <h1>Brousseauai</h1>
        <h1>Brousseauai</h1>
        <TabPanel value={value} index={0}>
            <Ravenous />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <WeatherIndex />
        </TabPanel>
      </div>
    )
}