import { render } from '@testing-library/react'
import React from 'react'
import Weather from './Weather'
import "./WeatherIndex.css"

export default class WeatherIndex extends React.Component {
    render() {
        return(
            <div>
                <Weather />
            </div>
        )
    }
}